export let scrollDirection;

(function() {

    'use strict';

    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
        if (window.scrollY > lastScrollTop) {
            document.documentElement.classList.remove('js-is-scrolling-up');
            document.documentElement.classList.add('js-is-scrolling-down');
            scrollDirection = 'down';
        } else if (window.scrollY < lastScrollTop) {
            document.documentElement.classList.remove('js-is-scrolling-down');
            document.documentElement.classList.add('js-is-scrolling-up');
            scrollDirection = 'up';
        }

        lastScrollTop = window.scrollY;
    });

}());
