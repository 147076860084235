/* eslint-disable max-lines-per-function */
import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function(Site) {

    'use strict';

    const loadJumplinksDependencies = async () => {
        await import('gumshoejs/dist/gumshoe.min.js').then((Gumshoe) => {
            const documentComputedStyle = getComputedStyle(document.documentElement);

            const secondaryNavigationHeight =
                parseInt(documentComputedStyle.getPropertyValue('--secondary-navigation-height')) || 0;

            const contentLanguagePickerHeight =
                parseInt(documentComputedStyle.getPropertyValue('--content-language-picker-height')) || 0;

            const chartControlBarHeight =
                parseInt(documentComputedStyle.getPropertyValue('--chart-control-bar-height')) || 0;

            const jumplinksHeight =
                parseInt(documentComputedStyle.getPropertyValue('--jumplinks-height')) || 0;

            // Matches "map.get($spacing, s-32)" in --sticky-target-scroll-offset-down
            const offsetMargin = 32;

            new Gumshoe('.js-jumplinks a', { // eslint-disable-line no-new
                offset: () => {
                    if (document.documentElement.classList.contains('js-is-scrolling-down')) {
                        return contentLanguagePickerHeight + chartControlBarHeight + jumplinksHeight + offsetMargin;
                    } else if (document.documentElement.classList.contains('js-is-scrolling-up')) {
                        return secondaryNavigationHeight + offsetMargin;
                    }
                    return 0;
                }
            });
        }).catch(error => console.log(error)); // eslint-disable-line no-console
    };

    const attachJumplinksDimensionsListener = (jumplinks) => {
        let rafJumplinksResizeListener;

        const jumplinksDimensionsListener = () => {
            const jumplinksHeight = jumplinks.offsetHeight;
            document.documentElement.style.setProperty('--jumplinks-height', `${jumplinksHeight}px`);
        };

        window.addEventListener('resize', () => {
            cancelAnimationFrame(rafJumplinksResizeListener);
            rafJumplinksResizeListener = requestAnimationFrame(jumplinksDimensionsListener);
        });

        jumplinksDimensionsListener();
    };

    const initializeJumplinksInstance = (jumplinks) => {
        // Update custom property so that other components can take it into account
        attachJumplinksDimensionsListener(jumplinks);

        // Attach class to HTML so that other components can take it into account
        document.documentElement.classList.add('js-jumplinks-enabled');
        document.documentElement.classList.add('js-sticky-scroll-interaction');

        // Set js-is-stuck class on Content language picker when it is sticky
        if ('IntersectionObserver' in window) {
            const jumplinksParent = jumplinks.parentNode;
            const jumplinksObserverOptions = {
                threshold: [1]
            };

            const jumplinksObserverCallback = (entries) => {
                if (entries[0].intersectionRatio === 1) {
                    entries[0].target.classList.remove('js-is-stuck');
                } else {
                    entries[0].target.classList.add('js-is-stuck');
                }
            };

            const jumplinksObserver = new IntersectionObserver(jumplinksObserverCallback, jumplinksObserverOptions);
            jumplinksObserver.observe(jumplinksParent);
        }
    };

    const initializeJumplinks = (context) => {
        const jumplinkComponents = context.querySelectorAll('.js-jumplinks');
        [...jumplinkComponents].forEach(jumplinks => {
            initializeJumplinksInstance(jumplinks);
        });
    };

    onDomReady(() => {
        if (document.querySelector('.js-jumplinks')) {
            loadJumplinksDependencies();
        }
    });

    Site.behaviors.jumplinks = {
        attach(context) {
            initializeJumplinks(context);
        }
    };

}(Site));
