(function(Site) {

    'use strict';

    let dataLayerEnabled;
    let dataLayer;
    const delay = 200;

    function getComponentData(element) {
        const dataLayerJson = element.dataset.cmpGaDataLayer;
        return dataLayerJson ? JSON.parse(dataLayerJson) : {};
    }

    function attachClickEventListener(element) {
        element.addEventListener('click', (event) => {
            const clickedElement = event.currentTarget;
            const href = clickedElement.getAttribute('href');
            if (href) {
                event.preventDefault();
            }

            const componentData = getComponentData(clickedElement);
            dataLayer.push(componentData);

            if (href) {
                setTimeout(() => {
                    window.location = href;
                }, delay);
            }
        });
    }

    function cleanUpLocalStorage() {
        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key) && key.includes('uniqueTracking_')) {
                localStorage.removeItem(key);
            }
        }
    }

    function attachUniqueClickEventListener(element) {
        cleanUpLocalStorage();
        element.addEventListener('click', (event) => {
            const clickedElement = event.currentTarget;

            if (!localStorage.getItem(`uniqueTracking_${clickedElement.id}`)) {
                const componentData = getComponentData(clickedElement);

                if (componentData) {
                    dataLayer.push(componentData);
                    localStorage.setItem(`uniqueTracking_${ clickedElement.id}`, true);
                }
            }
        });
    }

    function trackPageLoad(element) {
        const pageLoadData = getComponentData(element);
        dataLayer.push(pageLoadData);
    }

    function attachListeners(clickableElements, uniqueClickableElements) {
        const uniqueElementsArray = [...uniqueClickableElements];
        [...clickableElements].forEach(element => {
            if (!uniqueElementsArray.includes(element)) {
                attachClickEventListener(element);
            }
        });
        [...uniqueElementsArray].forEach(attachUniqueClickEventListener);
    }

    function initializeGoogleAnalytics(context) {
        dataLayerEnabled = document.body.hasAttribute('data-ga-layer-enabled');
        dataLayer = dataLayerEnabled ? window.dataLayer = window.dataLayer || [] : {};

        if (dataLayerEnabled) {
            const clickableElements = context.querySelectorAll('[data-cmp-ga-clickable]');
            const uniqueClickableElements = context.querySelectorAll('[data-cmp-ga-unique-tracking]');
            const pageLoads = context.querySelectorAll('[data-cmp-ga-page-load]');

            attachListeners(clickableElements, uniqueClickableElements);

            if (pageLoads !== null) {
                pageLoads.forEach((pageLoad) => {
                    trackPageLoad(pageLoad);
                });
            }
        }
    }

    Site.behaviors.googleAnalytics = {
        attach(context) {
            initializeGoogleAnalytics(context);
        }
    };

}(Site));
