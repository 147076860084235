(function(Site) {

    'use strict';

    const searchItemHide = (searchItem) => {
        searchItem.classList.add('js-tabs-list-result-hidden');
        searchItem.setAttribute('aria-hidden', 'true');
    };

    const searchItemShow = (searchItem) => {
        searchItem.classList.remove('js-tabs-list-result-hidden');
        searchItem.removeAttribute('aria-hidden');
    };

    const searchResultsHide = (tabsList) => {
        tabsList.classList.remove('cmp-tabs-list--search-enabled');
    };

    const searchResultsShow = (tabsList) => {
        tabsList.classList.add('cmp-tabs-list--search-enabled');
    };

    const searchMessageUpdate = (messageContainer, amountOfResults = null) => {
        switch (true) {
            case amountOfResults === 0:
                messageContainer.textContent = messageContainer.dataset.tabsListSearchMessageResultsNone;
                break;
            case amountOfResults === 1:
                messageContainer.textContent = messageContainer.dataset.tabsListSearchMessageResultsSingular;
                break;
            case amountOfResults > 1:
                const message = messageContainer.dataset.tabsListSearchMessageResultsPlural;
                messageContainer.textContent = message.replace('{amountOfResults}', amountOfResults);
                break;
            default:
                messageContainer.textContent = '';
        }
    };

    // eslint-disable-next-line max-lines-per-function
    const doSearch = (context, searchTerm, searchItems) => {
        if (!searchItems.length) {
            return false;
        }

        // String trimming + lowercasing + removing all accents using normalization & replacing special accents.
        const searchTermCleaned = searchTerm
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

        if (searchTermCleaned === '') {
            searchItems.forEach(searchItem => {
                searchItemHide(searchItem);
            });
            searchResultsHide(context);
            return false;
        }

        searchResultsShow(context);

        const matchingSearchItems = searchItems.filter(searchItem => searchItem.dataset.tabsListSearchItem
            .includes(searchTermCleaned));
        const nonMatchingSearchItems = searchItems.filter(searchItem => ![...matchingSearchItems]
            .includes(searchItem));

        if (matchingSearchItems) {
            [...nonMatchingSearchItems].forEach(searchItem => {
                searchItemHide(searchItem);
            });
        }

        [...matchingSearchItems].forEach(searchItem => {
            searchItemShow(searchItem);
        });

        return matchingSearchItems.length;
    };

    const copySearchItemsFromSourceToTarget = (sourceContainer, targetContainer) => {
        if (targetContainer.hasAttribute('data-tabs-list-search-results-copied')) {
            return;
        }

        const listElement = document.createElement('ul');
        listElement.classList.add('cmp-tabs-list__list');
        const searchItems = sourceContainer.querySelectorAll('[data-tabs-list-search-item]');
        [...searchItems].forEach((searchItem) => {
            const searchItemClone = searchItem.cloneNode(true);
            searchItemClone.classList.add('js-tabs-list-result-hidden');
            searchItemClone.setAttribute('aria-hidden', 'true');
            listElement.append(searchItemClone);
        });

        targetContainer.append(listElement);
        targetContainer.setAttribute('data-tabs-list-search-results-copied', true);
    };

    const initializeTabsListSearchInstance = (context) => {
        const searchInput = context.querySelector('.js-tabs-list-search-input');
        const searchMessageContainer = context.querySelector('.js-tabs-list-search-message');
        const sourceContainer = context.querySelector('.js-tabs-list-search-source-container');
        const targetContainer = context.querySelector('.js-tabs-list-search-target-container');

        if (!searchInput || !searchMessageContainer || !sourceContainer || !targetContainer) {
            return;
        }

        copySearchItemsFromSourceToTarget(sourceContainer, targetContainer);

        const searchItems = [...targetContainer.querySelectorAll('[data-tabs-list-search-item]')];

        searchInput.addEventListener('input', (event) => {
            const amountOfResults = doSearch(context, event.target.value, searchItems);
            searchMessageUpdate(searchMessageContainer, amountOfResults);
        });
    };

    const initializeTabsListSearch = (context) => {
        const tabsListSearchComponents = context.querySelectorAll('.js-tabs-list-search');
        [...tabsListSearchComponents].forEach(tabsListSearch => {
            initializeTabsListSearchInstance(tabsListSearch);
        });
    };

    Site.behaviors.tabsListSearch = {
        attach(context) {
            initializeTabsListSearch(context);
        }
    };

}(Site));
