(function(Site) {

    'use strict';

    const initializeSearchBoxes = (context) => {
        const searchBoxWebComponents = context.querySelector('oecd-searchbox');

        if (searchBoxWebComponents) {
            import('@oecd/app-searchbox-v1/dist/index.js').catch(error => console.log(error)); // eslint-disable-line max-len,no-console
        }
    };

    Site.behaviors.searchBoxWebComponents = {
        attach(context) {
            initializeSearchBoxes(context);
        }
    };

}(Site));
