import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    // eslint-disable-next-line max-lines-per-function
    const initializeHorizontalScrollContainer = (horizontalScrollContainer) => {
        const buttonPrev = horizontalScrollContainer.querySelector('.js-scroll-left');
        const buttonNext = horizontalScrollContainer.querySelector('.js-scroll-right');
        const scrollContainer = horizontalScrollContainer.querySelector('.js-horizontal-scroll-container');
        const scrollItemsArray = scrollContainer.querySelectorAll('a');
        const firstScrollItem = scrollItemsArray[0];
        const lastScrollItem = scrollItemsArray[scrollItemsArray.length - 1];
        let scrollContainerClientWidth = scrollContainer.clientWidth;

        // Show or hide left and right buttons.
        const scrollItemPairs = [
            {
                scrollItem: firstScrollItem,
                button: buttonPrev
            },
            {
                scrollItem: lastScrollItem,
                button: buttonNext
            }
        ];

        scrollItemPairs.forEach(pair => {
            function buttonHandler(entries) {
                entries.map((entry) => (
                    // eslint-disable-next-line max-len
                    entry.isIntersecting ? pair.button.classList.remove('js-shown') : pair.button.classList.add('js-shown')
                ));
            }

            const observer = new IntersectionObserver(buttonHandler, { threshold: 1 });
            observer.observe(pair.scrollItem);
        });

        // On button click: scroll overflowing tabs into view.
        buttonNext.addEventListener('click', () => {
            scrollContainer.scrollLeft += scrollContainerClientWidth / 2; // eslint-disable-line no-magic-numbers
        });

        buttonPrev.addEventListener('click', () => {
            scrollContainer.scrollLeft -= scrollContainerClientWidth / 2; // eslint-disable-line no-magic-numbers
        });

        // Recalculate tabListClientWidth on screen resize.
        window.addEventListener('resize', () => {
            scrollContainerClientWidth = scrollContainer.clientWidth;
        });
    };

    const initializeHorizontalScrollContainers = (context) => {
        const horizontalScrollContainers = context.querySelectorAll('.js-horizontal-scroll');
        [...horizontalScrollContainers].forEach(horizontalScrollContainer => {
            initializeHorizontalScrollContainer(horizontalScrollContainer);
        });
    };

    onDomReady(() => {
        initializeHorizontalScrollContainers(document);
    });

}());
