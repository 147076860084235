export const onDomReady = (callback) => {
    if (document.readyState !== 'loading') {
        setTimeout(callback, 0);
    } else {
        const listener = () => {
            callback();
            document.removeEventListener('DOMContentLoaded', listener);
        };
        document.addEventListener('DOMContentLoaded', listener);
    }
};
