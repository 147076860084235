/* eslint-disable max-lines-per-function */
import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const initializeMainNavigation = (context) => {
        // Height of the main navigation when font-size is set to largest in Chrome
        const maxMenuHeight = 120;

        const intentionalClickTimeframe = 450;
        const menuItemMouseEnterHandlerTimeout = 300;
        const menuItemMouseLeaveHandlerTimeout = 450;
        const mobileMenuTargetPanelTimeout = 600;

        const mainNavigation = context.querySelector('.main-navigation');
        const archiveMainNavigation = context.querySelector('.cmp-archive-main-navigation');
        const menuTrigger = context.querySelector('.js-main-menu-trigger');
        const menuCloseButtons = context.querySelectorAll('.js-main-menu-close');
        const mobileMenu = document.getElementById('mobilemenu');
        const mobileMenuList = context.querySelector('.js-mobile-navigation-list');
        const mobileMenuItems = context.querySelectorAll('.mobile-navigation__menu-item');
        const backButton = document.getElementById('mobileNavBackBtn');
        const currentLevelIndicator = context.querySelector('.mobile-navigation__current');

        const mobileMenuLogo = context.querySelector('.mobile-navigation__logo');
        const mobileMenuPanelTriggers = context.querySelectorAll('.js-mobile-navigation-panel-trigger');
        const mobileMenuPanels = context.querySelectorAll('.mobile-navigation__panel');
        let mobileMenuState = 'closed';

        const menu = context.querySelector('.cmp-main-navigation__menu');
        // eslint-disable-next-line max-len
        const menuItemsWithDropdowns = context.querySelectorAll('.cmp-main-navigation__menu-item:has(.cmp-main-navigation__dropdown-trigger)');
        // eslint-disable-next-line max-len
        const menuItemsWithLinks = context.querySelectorAll('.cmp-main-navigation__menu-item:has(.cmp-main-navigation__item-link)');
        const dropdownTriggers = context.querySelectorAll('.cmp-main-navigation__dropdown-trigger');
        const dropdownMenus = context.querySelectorAll('.cmp-main-navigation__dropdown-menu');

        const desktopMediaQueryListener = window.matchMedia('(min-width: 1298px)');

        // Update Main navigation custom property
        const attachMainNavigationDimensionsListener = () => {
            const htmlEl = document.documentElement;
            let rafMainNavigationResizeListener;

            const setMainNavigationDimensionsListener = () => {
                // eslint-disable-next-line max-len
                const mainNavigationHeight = mainNavigation.offsetHeight > maxMenuHeight ? maxMenuHeight : mainNavigation.offsetHeight;

                htmlEl.style.setProperty('--main-navigation-height', `${mainNavigationHeight}px`);
            };

            window.addEventListener('resize', () => {
                htmlEl.style.removeProperty('--main-navigation-height');

                cancelAnimationFrame(rafMainNavigationResizeListener);
                rafMainNavigationResizeListener = requestAnimationFrame(setMainNavigationDimensionsListener);
            });

            setMainNavigationDimensionsListener();
        };

        // If the archive rendition of the page is requested, don't do this.
        if (mainNavigation && !archiveMainNavigation) {
            attachMainNavigationDimensionsListener();
        }

        const createBackdrop = () => {
            const desktopMenuBackDrop = document.createElement('div');
            desktopMenuBackDrop.classList.add('js-main-navigation-hover-backdrop');
            document.body.appendChild(desktopMenuBackDrop);
        };

        const closeOpenDropdowns = (setFocusToLastTrigger, currentDropdownTrigger) => {
            const openDropdownTriggers = context
                .querySelectorAll('.cmp-main-navigation__dropdown-trigger[aria-expanded="true"]');
            const openDropdownMenuItems = context
                .querySelectorAll('.cmp-main-navigation__menu-item.js-dropdown-is-open');

            [...openDropdownMenuItems].forEach(openDropdownMenuItem => {
                if (!currentDropdownTrigger || openDropdownMenuItem !== currentDropdownTrigger.menuItem) {
                    openDropdownMenuItem.classList.remove('js-dropdown-is-open');
                }
            });

            [...openDropdownTriggers].forEach(openDropdownTrigger => {
                openDropdownTrigger.setAttribute('aria-expanded', 'false');

                if (setFocusToLastTrigger) {
                    openDropdownTrigger.focus();
                }
            });
        };

        const handleResponsiveEventListeners = () => {
            if (desktopMediaQueryListener.matches) {
                if (mobileMenuState !== 'closed') {
                    mobileMenu.close();
                }
            }
        };

        const updateCurrentLevelTitle = (currentTitle) => {
            currentLevelIndicator.textContent = currentTitle;
        };

        const resetMobileMenu = (focusableTrigger) => {
            if (mobileMenuState === 'level 1 active') {
                // Set inert on all panels
                mobileMenuPanels.forEach(mobileMenuPanel => {
                    mobileMenuPanel.inert = true;
                });

                // Remove inert on all menuItemsWithDropdowns
                mobileMenuItems.forEach(mobileMenuItem => {
                    mobileMenuItem.inert = false;
                    mobileMenuItem.removeAttribute('role');
                });

                // Make panel trigger focusable and discoverable for AT
                [...mobileMenuPanelTriggers].forEach(mobileMenuPanelTrigger => {
                    mobileMenuPanelTrigger.setAttribute('aria-expanded', 'false');
                    mobileMenuPanelTrigger.removeAttribute('tabindex');
                    mobileMenuPanelTrigger.removeAttribute('aria-hidden');
                    mobileMenuPanelTrigger.inert = false;
                });

                if (focusableTrigger) {
                    focusableTrigger.focus();
                }

                updateCurrentLevelTitle('');

                // Remove active class from mobile menu
                mobileMenu.classList.remove('js-first-level-open');
                mobileMenuList.removeAttribute('role');
                mobileMenuState === 'open';
            }
        };

        const openMobileMenu = () => {
            document.body.classList.add('js-mobile-menu-is-open');
            document.documentElement.style.overflowY = 'hidden';
            document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
            document.documentElement.style.position = 'fixed';
            mobileMenu.showModal();
            mobileMenuLogo.focus();

            mobileMenuState = 'open';
        };

        // ----------------
        // Mobile behaviour
        // ----------------
        if (mobileMenu && menuTrigger) {
            menuTrigger.addEventListener('click', openMobileMenu, false);

            [...menuCloseButtons].forEach(menuCloseBtn => {
                menuCloseBtn.addEventListener('click', (event) => {
                    mobileMenu.close();
                });
            });

            // Click outside.
            mobileMenu.addEventListener('click', (event) => {
                if (event.target === mobileMenu) {
                    mobileMenu.close();
                }
            });

            // SET ALL PANELS INERT
            mobileMenuPanels.forEach(mobileMenuPanel => {
                mobileMenuPanel.inert = true;
            });

            // todo: backButton not needed if all links?
            backButton.addEventListener('click', (event) => {
                const targetTrigger = context.querySelector('.js-mobile-navigation-panel-trigger[aria-expanded=true]');
                resetMobileMenu();

                if (targetTrigger) {
                    resetMobileMenu(targetTrigger);
                }
            });

            mobileMenu.addEventListener('close', (event) => {
                document.documentElement.style.overflowY = '';
                document.documentElement.style.paddingInlineEnd = '';
                document.documentElement.style.position = '';
                document.body.classList.remove('js-mobile-menu-is-open');
                resetMobileMenu();
                mobileMenuState = 'closed';
            });

            [...mobileMenuPanelTriggers].forEach(mobileMenuPanelTrigger => {
                const mobileHeaderTitle = mobileMenuPanelTrigger.getAttribute('data-mobile-header-title');

                mobileMenuPanelTrigger.addEventListener('click', () => {
                    if (mobileMenuPanelTrigger.getAttribute('aria-expanded') === 'false') {
                        mobileMenuPanelTrigger.setAttribute('aria-expanded', 'true');
                        // Hide from AT and tab
                        mobileMenuPanelTrigger.setAttribute('tabindex', '-1');
                        mobileMenuPanelTrigger.setAttribute('aria-hidden', 'true');
                        mobileMenuPanelTrigger.inert = true;
                        mobileMenuList.setAttribute('role', 'presentation');
                    }

                    updateCurrentLevelTitle(mobileHeaderTitle);

                    mobileMenu.classList.add('js-first-level-open');
                    mobileMenuState = 'level 1 active';

                    const targetPanel = document.getElementById(mobileMenuPanelTrigger.getAttribute('data-target'));
                    targetPanel.inert = false;

                    // Set inactive menu items inert
                    const inActivePanels = [...mobileMenuPanels].filter((panel) => panel !== targetPanel);

                    inActivePanels.forEach(inActivePanel => {
                        inActivePanel.classList.remove('mobile-navigation__panel--active');
                        const inActiveMenuItem = inActivePanel.closest('.mobile-navigation__menu-item');
                        inActiveMenuItem.inert = true;
                        inActiveMenuItem.setAttribute('role', 'presentation');
                    });

                    targetPanel.classList.add('mobile-navigation__panel--active');

                    setTimeout(() => {
                        targetPanel.focus();
                    }, mobileMenuTargetPanelTimeout);
                }, false);
            });

            desktopMediaQueryListener.addEventListener('change', handleResponsiveEventListeners);
        }

        // -----------------
        // Desktop behaviour
        // -----------------

        const dropdownMenuTransitionEndHandler = (event) => {
            // eslint-disable-next-line max-len
            if (event.target.classList.contains('cmp-main-navigation__dropdown-menu')) {
                const dropdownMenuComputedStyle = window.getComputedStyle(event.target);
                const target = event.target.id;
                // eslint-disable-next-line max-len
                const trigger = mainNavigation.querySelector(`.cmp-main-navigation__dropdown-trigger[data-target=${target}]`);

                if (dropdownMenuComputedStyle.visibility === 'hidden') {
                    trigger.completeVisible = false;
                } else if (dropdownMenuComputedStyle.visibility === 'visible') {
                    trigger.completeVisible = true;
                }
            }
        };

        const toggleDropDownOnClick = (dropdownTrigger) => {
            if (dropdownTrigger.getAttribute('aria-expanded') === 'true') {
                dropdownTrigger.setAttribute('aria-expanded', 'false');
                dropdownTrigger.menuItem.classList.remove('js-dropdown-is-open');
                document.body.classList.remove('js-dropdown-menu-is-open');
                dropdownTrigger.menuItem.mouseEntered = false;
            } else {
                // Close other active dropdowns and open targeted
                closeOpenDropdowns(false, dropdownTrigger);
                document.body.classList.add('js-dropdown-menu-is-open');
                dropdownTrigger.setAttribute('aria-expanded', 'true');
            }
        };

        const menuItemMouseEnterHandler = (event) => {
            const menuItem = event.target;
            menuItem.mouseEntered = true;
            menuItem.mouseEnterTime = Date.now();

            // Don't trigger when language popover is open
            if (document.body.classList.contains('js-language-popover-is-open')) {
                return;
            }

            if (menuItem.hoverTimeout) {
                clearTimeout(menuItem.hoverTimeout);
            }

            menuItem.hoverTimeout = setTimeout(() => {
                document.body.classList.add('js-dropdown-menu-is-open');
                menuItem.classList.add('js-dropdown-is-open');

                // ensure ARIA states keep in sync
                // Convert the NodeList to an array and filter elements with aria-expanded="true"
                const expandedElements = Array.from(dropdownTriggers)
                    .filter(node => node.getAttribute('aria-expanded') === 'true');

                // Set aria-expanded to "false" for each of the filtered elements
                expandedElements.forEach(node => {
                    node.setAttribute('aria-expanded', 'false');
                });

                // Set the current item open
                menuItem.triggerEl.setAttribute('aria-expanded', 'true');

            }, menuItemMouseEnterHandlerTimeout);
        };

        const menuItemMouseLeaveHandler = (event) => {
            const menuItem = event.target;
            menuItem.mouseEntered = false;
            const hoverIsInDropDownMenu = menu.contains(event.relatedTarget);

            // Don't trigger when language popover is open
            if (document.body.classList.contains('js-language-popover-is-open')) {
                return;
            }

            if (menuItem.hoverTimeout) {
                clearTimeout(menuItem.hoverTimeout);
            }

            menuItem.hoverTimeout = setTimeout(() => {
                if (!hoverIsInDropDownMenu) {
                    document.body.classList.remove('js-dropdown-menu-is-open');
                }
                menuItem.classList.remove('js-dropdown-is-open');
                menuItem.triggerEl.setAttribute('aria-expanded', 'false');
            }, menuItemMouseLeaveHandlerTimeout);
        };

        const menuLinkItemMouseEnterHandler = (event) => {
            const menuItem = event.target;
            // Don't trigger when language popover is open
            if (document.body.classList.contains('js-language-popover-is-open')) {
                return;
            }

            if (menuItem.hoverTimeout) {
                clearTimeout(menuItem.hoverTimeout);
            }

            menuItem.hoverTimeout = setTimeout(() => {
                document.body.classList.remove('js-dropdown-menu-is-open');
            }, menuItemMouseEnterHandlerTimeout);
        };

        const dropdownTriggerClickHandler = (event) => {
            const dropdownTrigger = event.target;
            const {
                menuItem,
                completeVisible
            } = dropdownTrigger;

            // Ensure that menuItem exists and has the required properties
            if (!menuItem || typeof menuItem.mouseEntered === 'undefined') {
                return;
            }

            // Handle case where mouse has entered the dropdown item
            if (menuItem.mouseEntered) {
                const timeSinceMouseEnter = Date.now() - menuItem.mouseEnterTime;

                // Prevent unintentional clicks based on the mouseenter timing
                if (timeSinceMouseEnter < intentionalClickTimeframe) {
                    // eslint-disable-next-line consistent-return
                    return false;
                }

                // If dropdown is fully visible, toggle it; otherwise, log the visibility issue
                if (completeVisible) {
                    toggleDropDownOnClick(dropdownTrigger);
                }
            } else {
                // Mouse did not enter, simply toggle the dropdown
                toggleDropDownOnClick(dropdownTrigger);
            }
        };

        if (menu && menuItemsWithDropdowns.length && dropdownTriggers.length) {
            createBackdrop();

            [...menuItemsWithDropdowns].forEach(menuItem => {
                menuItem.triggerEl = menuItem.querySelector('.cmp-main-navigation__dropdown-trigger');
                menuItem.addEventListener('mouseenter', menuItemMouseEnterHandler, false);
                menuItem.addEventListener('mouseleave', menuItemMouseLeaveHandler, false);
            });

            [...menuItemsWithLinks].forEach(menuItem => {
                menuItem.addEventListener('mouseenter', menuLinkItemMouseEnterHandler, false);
            });

            [...dropdownMenus].forEach(dropdownMenu => {
                dropdownMenu.addEventListener('transitionend', dropdownMenuTransitionEndHandler, false);
            });

            // Close open menus on Escape and set focus to active item
            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape') {
                    document.body.classList.remove('js-dropdown-menu-is-open');

                    [...menuItemsWithDropdowns].forEach(menuItem => {
                        menuItem.classList.remove('js-dropdown-is-open');
                    });

                    closeOpenDropdowns(true);
                }
            });

            [...dropdownTriggers].forEach(dropdownTrigger => {
                dropdownTrigger.menuItem = dropdownTrigger.closest('.cmp-main-navigation__menu-item');
                dropdownTrigger.addEventListener('click', dropdownTriggerClickHandler, false);
            });

            // Close open dropdowns when focus is out of menu
            menu.addEventListener('focusout', (event) => {
                if (event.relatedTarget) {
                    // If focus is still in the main menu, do nothing
                    if (menu.contains(event.relatedTarget)) {
                        return;
                    }
                } else if (menu.contains(event.target) && menu.contains(event.srcElement)) {
                    // Elements that have tabindex don't have a related target
                    return;
                }

                // Otherwise, close the dropdowns and leave focus alone
                closeOpenDropdowns();
                document.body.classList.remove('js-dropdown-menu-is-open');
            });
        }
    };

    onDomReady(() => {
        initializeMainNavigation(document);
    });

}());
