import {
    A11y, Navigation
} from 'swiper/modules';
import Swiper from 'swiper';

(function(Site) {

    'use strict';

    // eslint-disable-next-line max-lines-per-function
    const initializeListCarousels = (context) => {
        const listCarousels = context.querySelectorAll('.js-list-carousel');
        [...listCarousels].forEach(listCarousel => {
            const config = {
                modules: [Navigation, A11y],
                slidesPerView: 'auto',
                spaceBetween: 32,
                navigation: {
                    nextEl: listCarousel.querySelector('.swiper-button-next'),
                    prevEl: listCarousel.querySelector('.swiper-button-prev')
                },
                a11y: {
                    // Labels are set on the swiper instance through data attributes
                    prevSlideMessage: listCarousel?.dataset.previouslabel,
                    nextSlideMessage: listCarousel?.dataset.nextlabel,
                    containerMessage: listCarousel?.dataset.topiclabel,
                    slideLabelMessage: `${listCarousel?.dataset.slidelabel} {{index}} / {{slidesLength}}`,
                    slideRole: 'listitem'
                }
            };
            const swiper = new Swiper(listCarousel, config);
        });
    };

    Site.behaviors.list = {
        attach(context) {
            initializeListCarousels(context);
        }
    };

}(Site));
