import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    // eslint-disable-next-line max-lines-per-function
    const initializeLanguageSwitch = (context) => {
        const languageSwitch = context.querySelector('.cmp-language-switch');
        const languageSwitchDropdownTrigger = document.getElementById('language-dropdown-trigger');
        const languageSwitchPopover = document.getElementById('language-popover');

        const closeLanguageSwitchPopOverOnClickOutside = (event) => {
            if (!event.target.closest('.cmp-language-switch')) {
                languageSwitchPopover.close();
                languageSwitchDropdownTrigger.focus();
            }
        };

        const escapeHandler = (event) => {
            if (event.key === 'Escape') {
                languageSwitchPopover.close();
                languageSwitchDropdownTrigger.focus();
            }
        };

        const openLanguageSwitchPopover = () => {
            languageSwitchDropdownTrigger.setAttribute('aria-expanded', 'true');
            document.body.classList.add('js-language-popover-is-open');
            languageSwitchPopover.show();

            // Close popover on Escape
            context.addEventListener('keyup', escapeHandler, false);

            // Close popover when clicked outside
            context.addEventListener('click', closeLanguageSwitchPopOverOnClickOutside, false);
        };

        const createBackdrop = () => {
            const languagePopoverBackDrop = document.createElement('div');
            languagePopoverBackDrop.classList.add('js-language-switch-backdrop');
            document.body.appendChild(languagePopoverBackDrop);
        };

        const navigateToHrefAndClosePopover = (event, languageSwitchPopoverElement, languageSwitchLink) => {
            event.preventDefault();
            const href = languageSwitchLink.getAttribute('href');
            languageSwitchPopoverElement.close();
            window.location.href = href;
        };

        if (languageSwitch && languageSwitchDropdownTrigger && languageSwitchPopover) {
            const languageSwitchLinks = languageSwitchPopover.querySelectorAll('a');

            createBackdrop();

            languageSwitchDropdownTrigger.addEventListener('click', (event) => {
                if (languageSwitchDropdownTrigger.getAttribute('aria-expanded') === 'false') {
                    openLanguageSwitchPopover();
                } else {
                    languageSwitchPopover.close();
                }
            });

            languageSwitch.addEventListener('focusout', (event) => {
                // If focus is in the language switch popover or trigger do nothing
                if (languageSwitch.contains(event.relatedTarget)) {
                    return;
                }
                // If focus leaves close the popover
                languageSwitchPopover.close();
            });

            languageSwitchPopover.addEventListener('close', (event) => {
                document.body.classList.remove('js-language-popover-is-open');
                languageSwitchDropdownTrigger.setAttribute('aria-expanded', 'false');
                context.removeEventListener('click', closeLanguageSwitchPopOverOnClickOutside, false);
                context.removeEventListener('keyup', escapeHandler, false);
            });

            [...languageSwitchLinks].forEach(languageSwitchLink => {
                languageSwitchLink.addEventListener('click', (event) => {
                    navigateToHrefAndClosePopover(event, languageSwitchPopover, languageSwitchLink);
                }, false);

                languageSwitchLink.addEventListener('pointerdown', (event) => {
                    navigateToHrefAndClosePopover(event, languageSwitchPopover, languageSwitchLink);
                }, false);
            });
        }
    };

    onDomReady(() => {
        initializeLanguageSwitch(document);
    });

}());
