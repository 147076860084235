/* eslint-disable max-lines-per-function */
import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const toggleFullscreen = (expandableElement) => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            expandableElement.requestFullscreen();
        }
    };

    const initializeExpandBtnEventListener = (context) => {
        const expandBtns = context.querySelectorAll('.js-descriptive-asset-expand');
        if (expandBtns) {
            [...expandBtns].forEach(expandBtn => {
                if (document.fullscreenEnabled) {
                    const expandableElement = expandBtn.closest('.js-descriptive-asset');

                    expandableElement.addEventListener('fullscreenchange', (event) => {
                        if (document.fullscreenElement) {
                            expandableElement.classList.add('js-in-fullscreen-mode');
                            expandBtn.setAttribute('aria-pressed', 'true');
                        } else {
                            expandableElement.classList.remove('js-in-fullscreen-mode');
                            expandBtn.setAttribute('aria-pressed', 'false');
                            expandBtn.focus();
                        }
                    });

                    expandBtn.addEventListener('click', () => {
                        toggleFullscreen(expandableElement);
                    }, false);
                } else {
                    expandBtn.setAttribute('hidden', 'true');
                }
            });
        }
    };

    onDomReady(() => {
        initializeExpandBtnEventListener(document);
    });

}());
