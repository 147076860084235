/* eslint-disable no-use-before-define */
const handleFirstKeyDownWhenUserIsTabbing = (event) => {
    if (event.key === 'Tab') {
        document.body.classList.add('js-user-is-tabbing');
        window.removeEventListener('keydown', handleFirstKeyDownWhenUserIsTabbing);
        window.addEventListener('mousedown', handleFirstMouseDownWhenUserIsTabbing);
    }
};

const handleFirstMouseDownWhenUserIsTabbing = () => {
    document.body.classList.remove('js-user-is-tabbing');
    window.removeEventListener('mousedown', handleFirstMouseDownWhenUserIsTabbing);
    window.addEventListener('keydown', handleFirstKeyDownWhenUserIsTabbing);
};

window.addEventListener('keydown', handleFirstKeyDownWhenUserIsTabbing);
