import { ajax } from '@oecd/util-site/ajax';

(function() {

    'use strict';

    const DIALOG_DESTROY_TIMEOUT = 500;

    const openDialog = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        dialog.showModal();
    };

    const destroyDialog = (dialog) => {
        setTimeout(() => dialog.remove(), DIALOG_DESTROY_TIMEOUT);
    };

    // eslint-disable-next-line max-lines-per-function
    const loadDialog = async (chart, compareUrl) => {
        try {
            const fragment = await ajax(compareUrl);

            // Get dialog and close btn node.
            const fragmentModalEl = fragment.querySelector('.js-overlay');
            const overLayCloseBtn = fragment.querySelector('.js-modal-close-button');

            // Add it to document.
            document.body.appendChild(fragment);

            openDialog(fragmentModalEl);

            // Click outside.
            fragmentModalEl.addEventListener('click', (event) => {
                if (event.target === fragmentModalEl) {
                    fragmentModalEl.close();
                }
            });

            fragmentModalEl.addEventListener('close', (event) => {
                document.documentElement.style.overflowY = '';
                document.documentElement.style.paddingInlineEnd = '';
                destroyDialog(fragmentModalEl);
            });

            overLayCloseBtn.addEventListener('click', () => {
                fragmentModalEl.close();
            }, false);
        } catch (error) {
            window.console.warn('Overlay could not be triggered', error);
        }
    };

    document.addEventListener('cbChartActionButtonClicked', ({ detail }) => {
        const chart = document.querySelector(`oecd-chart[chart-id="${detail.chartId}"]`);

        if (chart) {
            const compareUrl = chart.getAttribute('data-chart-compare-url');
            if (compareUrl) {
                loadDialog(chart, compareUrl);
            }
        }
    });

}());
