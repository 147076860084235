import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    onDomReady(() => {
        if (document.querySelector('.js-oecd-search-app')) {
            import('@oecd/app-search-app-v1/dist/index.js').catch(error => console.log(error)); // eslint-disable-line max-len,no-console
        }
    });

}());
