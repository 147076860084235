(function(Site) {

    'use strict';

    const initializeFootnoteLinks = (context) => {
        const footnoteLinks = context.querySelectorAll('.js-footnote-link[href^="#"]');
        [...footnoteLinks].forEach(footnoteLink => {
            const target = footnoteLink.getAttribute('href').trim();
            if (target !== '') {
                const targetElement = context.querySelector(target);
                if (targetElement) {
                    targetElement.classList.add('js-footnote-target');
                }
            }
        });
    };

    Site.behaviors.footnote = {
        attach(context) {
            initializeFootnoteLinks(context);
        }
    };

}(Site));
