/* eslint-disable max-lines-per-function */
import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const openChartControlBarOverlay = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        dialog.showModal();
    };

    const closeChartControlBarOverlay = (dialog) => {
        dialog.close();
    };

    const attachChartControlBarDimensionsListener = (chartControlBar) => {
        let rafChartControlBarResizeListener;

        const chartControlBarDimensionsListener = () => {
            const chartControlBarHeight = chartControlBar.offsetHeight;
            document.documentElement.style.setProperty('--chart-control-bar-height', `${chartControlBarHeight}px`);
        };

        if ('MutationObserver' in window) {
            const observer = new MutationObserver(chartControlBarDimensionsListener);
            observer.observe(chartControlBar, {
                attributes: true, childList: true, subtree: true
            });
        }

        window.addEventListener('resize', () => {
            cancelAnimationFrame(rafChartControlBarResizeListener);
            rafChartControlBarResizeListener = requestAnimationFrame(chartControlBarDimensionsListener);
        });

        chartControlBarDimensionsListener();
    };

    const initializeChartControlBar = (chartControlBar) => {
        // Update custom property so that other components can take it into account
        attachChartControlBarDimensionsListener(chartControlBar);

        // Attach class to HTML so that other components can take it into account
        document.documentElement.classList.add('js-chart-control-bar-enabled');
        document.documentElement.classList.add('js-sticky-scroll-interaction');

        const chartControlBarOverlayButton = chartControlBar.querySelector('.js-chart-control-bar-button-overlay');

        if (chartControlBarOverlayButton) {
            const chartControlBarOverlay = chartControlBar.querySelector('.js-chart-control-bar-overlay');

            if (chartControlBarOverlay) {
                chartControlBarOverlayButton.addEventListener('click', () => {
                    openChartControlBarOverlay(chartControlBarOverlay);
                });

                // Click outside.
                chartControlBarOverlay.addEventListener('click', (event) => {
                    if (event.target === chartControlBarOverlay) {
                        closeChartControlBarOverlay(chartControlBarOverlay);
                    }
                });

                chartControlBarOverlay.addEventListener('close', (event) => {
                    document.documentElement.style.overflowY = '';
                    document.documentElement.style.paddingInlineEnd = '';
                });

                const chartControlBarCloseButton = chartControlBar.querySelector('.js-close-button');
                chartControlBarCloseButton?.addEventListener('click', () => {
                    closeChartControlBarOverlay(chartControlBarOverlay);
                }, false);
            }
        }

        // Set js-is-stuck class on Content language picker when it is sticky
        if ('IntersectionObserver' in window) {
            const chartControlBarParent = chartControlBar.parentNode;
            const chartControlBarObserverOptions = {
                threshold: [1]
            };

            const chartControlBarObserverCallback = (entries) => {
                if (entries[0].intersectionRatio === 1) {
                    entries[0].target.classList.remove('js-is-stuck');
                } else {
                    entries[0].target.classList.add('js-is-stuck');
                }
            };

            const intersectionObserver =
                new IntersectionObserver(chartControlBarObserverCallback, chartControlBarObserverOptions);
            intersectionObserver.observe(chartControlBarParent);
        }
    };

    const initializeChartControlBars = (context) => {
        const chartControlBars = context.querySelectorAll('.js-chart-control-bar');

        [...chartControlBars].forEach(chartControlBar => {
            initializeChartControlBar(chartControlBar);
        });
    };

    onDomReady(() => {
        initializeChartControlBars(document);
    });

}());
