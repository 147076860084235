(function(Site) {

    'use strict';
    // Utility to throttle callbacks
    const throttle = (callback, delay) => {
        let timer = 0;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay);
        };
    };

    const setScrollShadow = (scrollLeft, scrollSize, tableComponent) => {
        if (scrollLeft === 0) {
            tableComponent.classList.add('js-has-shadow-right');
            tableComponent.classList.remove('js-has-shadow-left');
        } else if (scrollLeft > 0 && scrollLeft < scrollSize) {
            tableComponent.classList.add('js-has-shadow-right', 'js-has-shadow-left');
        } else if (scrollLeft >= (scrollSize - 1)) {
            tableComponent.classList.remove('js-has-shadow-right');
            tableComponent.classList.add('js-has-shadow-left');
        }
    };

    const setTableOverflow = (tableComponent, scrollArea) => {
        tableComponent.classList.add('js-has-overflow');
        scrollArea.setAttribute('tabindex', '0');
    };

    const removeTableOverflow = (tableComponent, scrollArea) => {
        tableComponent.classList.remove('js-has-overflow');
        tableComponent.classList.remove('js-has-shadow-left');
        tableComponent.classList.remove('js-has-shadow-right');
        scrollArea.removeAttribute('tabindex');
    };

    const handleTableScroll = (contentTable, tableComponent, scrollArea) => {
        const scrollSize = contentTable.clientWidth - scrollArea.clientWidth;
        const scrollLeftPosition = Math.round(scrollArea.scrollLeft);

        setScrollShadow(scrollLeftPosition, scrollSize, tableComponent);
    };

    const handleTableOverflow = (table, scrollArea, tableComponent) => {
        let tableHasOverflow = false;

        // Handle Overflow
        if (table.clientWidth > scrollArea.clientWidth) {
            tableHasOverflow = true;
            setTableOverflow(tableComponent, scrollArea);
        } else {
            tableHasOverflow = false;
            removeTableOverflow(tableComponent, scrollArea);
        }

        // Handle Shadow
        if (tableHasOverflow) {
            tableComponent.classList.add('js-has-shadow-right');
            scrollArea.addEventListener('scroll', () => {
                handleTableScroll(table, tableComponent, scrollArea);
            });
        }
    };

    const checkTableForOverflow = (tableComponent) => {
        const scrollArea = tableComponent.querySelector('.js-table-scroll-area');
        const table = tableComponent.querySelector('table');

        if (scrollArea && table) {
            handleTableOverflow(table, scrollArea, tableComponent);
        }
    };

    const initializeResizeObserver = (nodesToWatch) => {
        const resizeObserverThrottleTime = 100;
        // Handling of overflow and shadows
        const checkForOverflowObserver = new ResizeObserver(
            throttle((entries) => {
                for (const entry of entries) {
                    const tableWrapper = entry.target;
                    checkTableForOverflow(tableWrapper);
                }
            }, resizeObserverThrottleTime)
        );

        // Observe the tables present on the page
        nodesToWatch.forEach((table) => {
            checkForOverflowObserver.observe(table);
        });
    };

    const initializeContentTables = (context) => {
        const contentTableComponents = context.querySelectorAll('.js-content-table');

        if (contentTableComponents.length) {
            if ('ResizeObserver' in window) {
                initializeResizeObserver(contentTableComponents);
            }
        }
    };

    Site.behaviors.table = {
        attach(context) {
            initializeContentTables(context);
        }
    };

}(Site));
