(function(Site) {

    'use strict';

    const loadFlourishEmbeddablesDependencies = async () => {
        await import('@oecd/component-embed-v1/flourish-google-analytics').then(() => {
            document.dispatchEvent(new Event('flourishEmbeddablesDependenciesLoaded'));
        }).catch(error => window.console.log(error));
    };

    const initializeFlourishEmbeddables = async (context) => {
        const flourishEmbeddables = context.querySelector('[data-chart-technology="flourish"]');

        if (flourishEmbeddables) {
            await loadFlourishEmbeddablesDependencies();
        }
    };

    Site.behaviors.embeds = {
        attach(context) {
            initializeFlourishEmbeddables(context);
        }
    };

}(Site));
