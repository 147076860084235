(function(Site) {

    'use strict';

    const loadChartWebComponentsDependencies = async () => {
        window.React || (window.React = await import('react'));
        window.ReactDOM || (window.ReactDOM = await import('react-dom'));
        await import('@oecd-pac/chart/dist/browser/oecd-chart-latest').then(() => {
            document.dispatchEvent(new Event('chartWebComponentsDependenciesLoaded'));
        }).catch(error => window.console.log(error));
    };

    const initializeChartWebComponents = async (context) => {
        const chartWebComponents = context.querySelector('oecd-control, oecd-chart');

        if (chartWebComponents) {
            await loadChartWebComponentsDependencies();
        }
    };

    Site.behaviors.chartWebComponents = {
        attach(context) {
            initializeChartWebComponents(context);
        }
    };

}(Site));
