import { onDomReady } from '@oecd/util-site/on-dom-ready';

// Class indicating that JS is enabled.
document.documentElement.classList.add('js-enabled');

(function(Site) {

    // Attach all behaviors.
    onDomReady(() => {
        Site.attachBehaviors(document);
    });

}(Site));
