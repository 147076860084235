import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const openSearchDialog = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        document.documentElement.classList.add('js-searchbox-dialog-is-open');
        dialog.showModal();
    };

    const closeSearchDialog = (dialog) => {
        document.documentElement.classList.remove('js-searchbox-dialog-is-open');
        dialog.close();
    };

    // eslint-disable-next-line max-lines-per-function
    const initializeSearchDialog = () => {
        const searchDialog = document.getElementById('searchbox-dialog');

        if (searchDialog) {
            const searchButtons = document.querySelectorAll('.js-searchbox-dialog-trigger');
            [...searchButtons].forEach((searchButton) => {
                searchButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    openSearchDialog(searchDialog);
                    return false;
                }, false);
            });

            // Click outside.
            searchDialog.addEventListener('click', (event) => {
                if (event.target === searchDialog) {
                    closeSearchDialog(searchDialog);
                }
            });

            searchDialog.addEventListener('close', (event) => {
                document.documentElement.style.overflowY = '';
                document.documentElement.style.paddingInlineEnd = '';
                document.documentElement.classList.remove('js-searchbox-dialog-is-open');
            });

            const searchDialogCloseButtons = searchDialog.querySelectorAll('.js-searchbox-dialog-close');
            [...searchDialogCloseButtons].forEach((searchDialogCloseButton) => {
                searchDialogCloseButton.addEventListener('click', () => {
                    closeSearchDialog(searchDialog);
                }, false);
            });
        }
    };

    onDomReady(() => {
        initializeSearchDialog();
    });

}());
