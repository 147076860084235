import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    // eslint-disable-next-line max-lines-per-function
    const initializeReportNavigationMobile = (context) => {
        const reportNavigation = context.querySelector('.js-report-navigation');
        const reportNavigationMobileDialog = context.querySelector('.js-report-navigation-mobile-dialog');
        const reportNavigationMobileBodyTarget =
            reportNavigationMobileDialog?.querySelector('.js-report-navigation-mobile-dialog-body');
        const reportNavigationMobileTrigger = context.querySelector('.js-report-navigation-mobile-trigger');
        const reportNavigationSource = context.querySelector('.js-report-navigation-source');
        let reportNavigationMobileMenuState = 'closed';
        // Store the offset so we can restore it on close.
        let scrollTop = 0;
        const scrollContainer = document.querySelector('.root.container');

        const desktopSmallMediaQueryListener = window.matchMedia('(min-width: 992px)');

        const reportNavigationInternalLinks = (() => {
            if (!reportNavigation) {
                return [];
            }

            const currentPage = window.location;
            const reportNavigationLinks = [...reportNavigation.querySelectorAll('a')];

            return reportNavigationLinks?.filter((link) => {
                const url = new URL(link.href);
                // Check if the href matches the pattern
                return url.pathname === currentPage.pathname && url.search === currentPage.search;
            });
        })();

        // eslint-disable-next-line max-lines-per-function
        const closeMobileReportNavigationAndNavigateToInternalLink = (event) => {
            if (event.target.href) {
                const url = new URL(event.target.href);
                const hashValue = url.hash.substring(1);

                if (hashValue) {
                    const targetElement = document.getElementById(hashValue);
                    if (targetElement) {
                        targetElement.setAttribute('tabindex', '-1');
                        reportNavigationMobileDialog.close();
                        targetElement.focus();
                        targetElement.removeAttribute('tabindex');
                    }
                } else {
                    reportNavigationMobileDialog.close();
                }
            }
            reportNavigationMobileDialog.close();
        };

        const isElementVisibleInContainer = (element, container) => {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            // Calculate the visible part of the container
            const containerTop = containerRect.top + container.scrollTop;
            const containerBottom = containerTop + container.clientHeight;

            // Check if the element is within the visible part of the container
            const isVisibleVertically = (elementRect.top < containerBottom) && (elementRect.bottom > containerTop);

            return isVisibleVertically;
        };

        const relocateReportNavigationToDesktop = () => {
            reportNavigationSource.appendChild(reportNavigation);
            [...reportNavigationInternalLinks].forEach(reportNavigationInternalLink => {
                reportNavigationInternalLink
                    .removeEventListener('click', closeMobileReportNavigationAndNavigateToInternalLink, false);
            });
        };

        const relocateReportNavigationToMobile = () => {
            reportNavigationMobileBodyTarget.appendChild(reportNavigation);
            [...reportNavigationInternalLinks].forEach(reportNavigationInternalLink => {
                reportNavigationInternalLink
                    .addEventListener('click', closeMobileReportNavigationAndNavigateToInternalLink, false);
            });
        };

        const handleResponsiveEventListeners = () => {
            if (desktopSmallMediaQueryListener.matches) {
                if (reportNavigationMobileMenuState !== 'closed') {
                    reportNavigationMobileDialog.close();
                }
                relocateReportNavigationToDesktop();
            } else {
                relocateReportNavigationToMobile();
            }
        };

        const openMobileReportNavigation = () => {
            scrollTop = window.scrollY;
            document.documentElement.classList.add('js-disable-smooth-scroll');
            document.body.classList.add('js-mobile-report-navigation-is-open');
            reportNavigationMobileDialog.showModal();
            scrollContainer.classList.add('js-scroll-is-fixed');
            scrollContainer.scroll(0, scrollTop);
            reportNavigationMobileMenuState = 'open';

            const activeNavigationLink = reportNavigationMobileDialog.querySelector('a[aria-current]');

            if (activeNavigationLink && !isElementVisibleInContainer(activeNavigationLink, reportNavigationMobileBodyTarget)) { // eslint-disable-line max-len
                activeNavigationLink.scrollIntoView();
            }
        };

        if (reportNavigationMobileTrigger && reportNavigation && reportNavigationMobileBodyTarget) {
            if (!desktopSmallMediaQueryListener.matches) {
                relocateReportNavigationToMobile();
            }

            reportNavigationMobileTrigger.addEventListener('click', openMobileReportNavigation, false);

            [...context.querySelectorAll('.js-report-navigation-mobile-dialog-close')].forEach(menuCloseBtn => {
                menuCloseBtn.addEventListener('click', (event) => {
                    reportNavigationMobileDialog.close();
                });
            });

            // Click outside.
            reportNavigationMobileDialog.addEventListener('click', (event) => {
                if (event.target === reportNavigationMobileDialog) {
                    reportNavigationMobileDialog.close();
                }
            });

            reportNavigationMobileDialog.addEventListener('close', (event) => {
                document.body.classList.remove('js-mobile-report-navigation-is-open');
                reportNavigationMobileMenuState = 'closed';
                window.scrollTo(0, scrollTop);
                document.documentElement.classList.remove('js-disable-smooth-scroll');

            });

            desktopSmallMediaQueryListener.addEventListener('change', handleResponsiveEventListeners);
        }
    };

    onDomReady(() => {
        initializeReportNavigationMobile(document);
    });

}());
