import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const updateSearchTabsLinkSearchTerm = (searchTabsLink, searchTerm) => {
        if (!searchTabsLink.hasAttribute('href')) {
            return;
        }

        const searchTermTrimmed = searchTerm.trim().toLowerCase();
        const url = new URL(searchTabsLink.getAttribute('href'), window.location.origin);

        if (searchTermTrimmed.length) {
            url.searchParams.set('q', encodeURIComponent(searchTermTrimmed));
        } else if (url.searchParams.has('q')) {
            url.searchParams.delete('q');
        }

        searchTabsLink.setAttribute('href', url.toString().substring(url.origin.length));
    };

    const isHorizontallyVisible = (element, parent) => {
        const elementRect = element.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        // Check if the element is horizontally within the parent's visible area
        return elementRect.left >= parentRect.left && elementRect.right <= parentRect.right;
    };

    const scrollToActiveElement = (searchTabs) => {
        const activeItem = searchTabs.querySelector('[aria-current="page"]');
        const scrollArea = searchTabs.querySelector('ul');

        if (activeItem && scrollArea) {
            // check if current element is visible inside scrollable area
            // else scroll it into view
            if (!isHorizontallyVisible(activeItem, scrollArea)) {
                scrollArea.scrollTo(activeItem.offsetLeft, 0);
            }
        }
    };

    const initializeSearchTabsEventListener = (context) => {
        const searchTabs = context.querySelectorAll('.js-search-tabs');
        if (searchTabs) {
            context.addEventListener('searchBoxValueUpdate', (event) => {
                const searchTerm = event.detail?.searchTerm;
                if (searchTerm !== undefined) { // eslint-disable-line no-undefined
                    searchTabs.forEach(searchTabsInstance => {
                        const searchTabsLinks = searchTabsInstance.querySelectorAll('.js-search-tabs-link');
                        [...searchTabsLinks].forEach(searchTabsLink => {
                            updateSearchTabsLinkSearchTerm(searchTabsLink, searchTerm);
                        });
                    });
                }
            });

            searchTabs.forEach(searchTabsInstance => {
                scrollToActiveElement(searchTabsInstance);
            });
        }
    };

    onDomReady(() => {
        initializeSearchTabsEventListener(document);
    });

}());
