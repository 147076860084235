import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const openReportHeaderOverlay = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        dialog.showModal();
    };

    const closeReportHeaderOverlay = (dialog) => {
        dialog.close();
    };

    // eslint-disable-next-line max-lines-per-function
    const initializeReportHeader = (reportHeader) => {
        const reportHeaderOverlayButton = reportHeader.querySelector('.js-report-header-button-overlay');

        if (reportHeaderOverlayButton) {
            const reportHeaderOverlay = reportHeader.querySelector('.js-report-header-overlay');

            if (reportHeaderOverlay) {
                reportHeaderOverlayButton.addEventListener('click', () => {
                    openReportHeaderOverlay(reportHeaderOverlay);
                });

                // Click outside.
                reportHeaderOverlay.addEventListener('click', (event) => {
                    if (event.target === reportHeaderOverlay) {
                        closeReportHeaderOverlay(reportHeaderOverlay);
                    }
                });

                reportHeaderOverlay.addEventListener('close', (event) => {
                    document.documentElement.style.overflowY = '';
                    document.documentElement.style.paddingInlineEnd = '';
                });

                const reportHeaderCloseButton = reportHeader.querySelector('.js-close-button');
                reportHeaderCloseButton?.addEventListener('click', () => {
                    closeReportHeaderOverlay(reportHeaderOverlay);
                }, false);

                const editionsForm = reportHeaderOverlay.querySelector('.js-report-header-editions-form');

                if (editionsForm) {
                    const select = editionsForm.querySelector('.js-report-header-editions-form-select');
                    const submit = editionsForm.querySelector('.js-report-header-editions-form-submit');

                    if (select && submit) {
                        editionsForm.addEventListener('submit', (event) => {
                            event.preventDefault();
                            if (select.value) {
                                window.location.href = select.value;
                                closeReportHeaderOverlay(reportHeaderOverlay);
                            }
                        });
                    }
                }
            }
        }
    };

    const initializeReportHeaders = (context) => {
        const reportHeaders = context.querySelectorAll('.js-report-header');

        [...reportHeaders].forEach(reportHeader => {
            initializeReportHeader(reportHeader);
        });
    };

    onDomReady(() => {
        initializeReportHeaders(document);
    });

}());
