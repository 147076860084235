/* eslint-disable */
import {onDomReady} from '@oecd/util-site/on-dom-ready';

(function () {

    'use strict';

    const openContentLanguagePickerOverlay = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        dialog.showModal();
    };

    const closeContentLanguagePickerOverlay = (dialog) => {
        dialog.close();
    };

    // Set a class on the Content Language Picker if the available languages don't fit in the component
    const toggleLanguagesOverflowClass = (elem, contentLanguagePickerNode) => {
        const isOverflowing = elem.scrollWidth > elem.offsetWidth;
        if (isOverflowing) {
            contentLanguagePickerNode.classList.add('js-content-language-picker-overlay-mode');
        } else {
            contentLanguagePickerNode.classList.remove('js-content-language-picker-overlay-mode');
        }
    };

    // Set custom variables in order to respond to UI changes
    const attachContentLanguagePickerDimensionsListener = (contentLanguagePicker) => {
        const availableLanguages = contentLanguagePicker.querySelector('.cmp-content-language-picker__languages');
        let rafContentLanguagePickerResizeListener;

        const setContentLanguagePickerDimensionsListener = () => {
            const contentLanguagePickerHeight = contentLanguagePicker.offsetHeight;
            document.documentElement.style.setProperty('--content-language-picker-height', `${contentLanguagePickerHeight}px`);

            if (availableLanguages) {
                const availableLanguagesHeight = availableLanguages.offsetHeight;
                document.documentElement.style.setProperty('--content-language-picker-languages-height', `${availableLanguagesHeight}px`);
            }
        };

        window.addEventListener('resize', () => {
            cancelAnimationFrame(rafContentLanguagePickerResizeListener);
            rafContentLanguagePickerResizeListener = requestAnimationFrame(setContentLanguagePickerDimensionsListener);
        });

        setContentLanguagePickerDimensionsListener();
    };

    const initializeContentLanguagePicker = (contentLanguagePicker) => {
        const contentLanguagePickerOverlayTrigger = contentLanguagePicker.querySelector('.js-content-language-picker-overlay-trigger');
        const contentLanguagePickerOverlay = contentLanguagePicker.querySelector('.js-content-language-picker-overlay');
        const contentLanguagePickerForm = contentLanguagePicker.querySelector('.js-available-languages-form');
        const availableLanguagesList = contentLanguagePicker.querySelector('.cmp-content-language-picker__available-languages');
        const citeOverlayTrigger = contentLanguagePicker.querySelector('.js-cite-overlay-trigger');
        const citeOverlay = contentLanguagePicker.querySelector('.js-cite-overlay');

        const contentLanguagePickerActions = contentLanguagePicker.querySelector('.cmp-content-language-picker__actions');
        if (!contentLanguagePickerActions) {
            contentLanguagePicker.parentNode.classList.add('js-has-no-actions');
        }

        // Update custom property so that other components can take it into account
        attachContentLanguagePickerDimensionsListener(contentLanguagePicker);

        // Attach class to HTML so that other components can take it into account
        document.documentElement.classList.add('js-content-language-picker-enabled');
        document.documentElement.classList.add('js-sticky-scroll-interaction');

        // Enable Textual overlay
        if (citeOverlayTrigger && citeOverlay) {
            const citeOverlayCloseButton = citeOverlay.querySelector('.js-close-button');

            citeOverlayTrigger.addEventListener('click', () => {
                openContentLanguagePickerOverlay(citeOverlay);
            });

            citeOverlay.addEventListener('click', (event) => {
                if (event.target === citeOverlay) {
                    closeContentLanguagePickerOverlay(citeOverlay);
                }
            });

            citeOverlay.addEventListener('close', (event) => {
                document.documentElement.style.overflowY = '';
                document.documentElement.style.paddingInlineEnd = '';
            });

            citeOverlayCloseButton.addEventListener('click', (event) => {
                event.preventDefault();
                closeContentLanguagePickerOverlay(citeOverlay);
            }, false);
        }

        // Enable language selection overlay and overflow logic
        if (contentLanguagePickerOverlay && availableLanguagesList) {
            const contentLanguagePickerOverlayCloseButtons = contentLanguagePickerOverlay.querySelectorAll('.js-close-button');

            contentLanguagePickerOverlayTrigger.addEventListener('click', () => {
                openContentLanguagePickerOverlay(contentLanguagePickerOverlay);
            });

            // Click outside.
            contentLanguagePickerOverlay.addEventListener('click', (event) => {
                if (event.target === contentLanguagePickerOverlay) {
                    closeContentLanguagePickerOverlay(contentLanguagePickerOverlay);
                }
            });

            contentLanguagePickerOverlay.addEventListener('close', (event) => {
                document.documentElement.style.overflowY = '';
                document.documentElement.style.paddingInlineEnd = '';
            });

            [...contentLanguagePickerOverlayCloseButtons].forEach(contentLanguagePickerOverlayCloseButton => {
                contentLanguagePickerOverlayCloseButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    closeContentLanguagePickerOverlay(contentLanguagePickerOverlay);
                }, false);
            });

            contentLanguagePickerForm.addEventListener('submit', (event) => {
                event.preventDefault();
                window.location.href = contentLanguagePickerForm.querySelector('input:checked').value;
            });

            // check if the languages fit into the Content Language Picker
            if ('ResizeObserver' in window) {
                const availableLanguagesListResizeObserver = new ResizeObserver(entries => {
                    toggleLanguagesOverflowClass(entries[0].target, contentLanguagePicker);
                });
                availableLanguagesListResizeObserver.observe(availableLanguagesList);
            }
        }

        // Set js-is-stuck class on Content language picker when it is sticky
        if ('IntersectionObserver' in window) {
            const contentLanguagePickerParent = contentLanguagePicker.parentNode;
            const contentLanguagePickerObserverOptions = {
                threshold: [1]
            };

            const contentLanguagePickerObserverCallback = (entries) => {
                if (entries[0].intersectionRatio === 1) {
                    entries[0].target.classList.remove('js-is-stuck');
                } else {
                    entries[0].target.classList.add('js-is-stuck');
                }
            };

            const contentLanguagePickerObserver = new IntersectionObserver(contentLanguagePickerObserverCallback, contentLanguagePickerObserverOptions);
            contentLanguagePickerObserver.observe(contentLanguagePickerParent);
        }
    };

    const initializeContentLanguagePickers = (context) => {
        const contentLanguagePickers = context.querySelectorAll('.js-content-language-picker');
        [...contentLanguagePickers].forEach(contentLanguagePicker => {
            initializeContentLanguagePicker(contentLanguagePicker);
        });
    };

    onDomReady(() => {
        initializeContentLanguagePickers(document);
    });

}());
