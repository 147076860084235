(function(Site) {

    'use strict';

    let dataLayerEnabled;
    let dataLayer;

    const initializeChartGoogleAnalytics = (context) => {
        dataLayerEnabled = document.body.hasAttribute('data-ga-layer-enabled');
        dataLayer = dataLayerEnabled ? window.dataLayer = window.dataLayer || [] : {};

        if (dataLayerEnabled) {
            context.addEventListener('cbTrackingEvent', ({ detail }) => {
                dataLayer.push(detail);
            });
        }
    };

    Site.behaviors.chartGoogleAnalytics = {
        attach(context) {
            initializeChartGoogleAnalytics(context);
        }
    };

}(Site));
