window.Site = { behaviors: {} };

(function(Site) {

    Site.throwError = (error) => {
        setTimeout(() => {
            throw error;
        });
    };

    Site.attachBehaviors = (context = document) => {
        const { behaviors } = Site;
        Object.keys(behaviors || {}).forEach((index) => {
            if (typeof behaviors[index].attach === 'function') {
                try {
                    behaviors[index].attach(context);
                } catch (error) {
                    Site.throwError(error);
                }
            }
        });
    };

    Site.detachBehaviors = (context = document, trigger = 'unload') => {
        const { behaviors } = Site;
        // Execute all of them.
        Object.keys(behaviors || {}).forEach((index) => {
            if (typeof behaviors[index].detach === 'function') {
                try {
                    behaviors[index].detach(context, trigger);
                } catch (error) {
                    Site.throwError(error);
                }
            }
        });
    };

}(Site));
