(function(Site) {

    'use strict';

    const copyToClipboard = (link) => {
        const copyUrl = link.getAttribute('href');
        navigator.clipboard.writeText(copyUrl);
    };

    const initializeTitleCopyIcons = (context) => {

        const copyIcons = context.querySelectorAll('.js-title-copy-icon');

        if (copyIcons.length > 0) {
            context.addEventListener('click', (event) => {
                if (event.target.classList.contains('js-title-copy-icon')) {
                    event.preventDefault();
                    copyToClipboard(event.target);
                }
            }, false);
        }
    };

    Site.behaviors.titleCopyIcon = {
        attach(context) {
            initializeTitleCopyIcons(context);
        }
    };

}(Site));
