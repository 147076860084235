(function(Site) {

    'use strict';
    const delay = 200;

    const enrichURLWithEmail = (event, buttonLink, emailInput) => {
        event.preventDefault();
        if (buttonLink.hasAttribute('data-subscribe-banner-path') && emailInput.checkValidity()) {
            const email = emailInput.value;
            const originalUrl = buttonLink.getAttribute('data-subscribe-banner-path');
            const url = new URL(originalUrl);
            url.searchParams.append('email', email);
            setTimeout(() => {
                window.location.href = url.toString();
            }, delay);
        } else {
            emailInput.reportValidity();
        }
    };

    const initializeSubscribeBanner = (banner) => {
        const emailInput = banner.querySelector('[data-email-input]');

        emailInput.setCustomValidity('');

        emailInput.addEventListener('invalid', (event) => {
            emailInput.setCustomValidity(emailInput.getAttribute('data-invalid-message'));
            emailInput.classList.add('is-invalid');
        });

        emailInput.addEventListener('input', (event) => {
            emailInput.setCustomValidity('');
            emailInput.checkValidity();
        });

        const buttonLink = banner.querySelector('.js-subscribe-banner-button');
        const subscribeBannerForm = banner.querySelector('.js-subscribe-banner-form');

        subscribeBannerForm.addEventListener('submit', (event) => enrichURLWithEmail(event, buttonLink, emailInput));
    };

    const initializeSubscribeBanners = (context) => {
        const subscribeBanners = context.querySelectorAll('.js-subscribe-banner');

        subscribeBanners.forEach((banner) => {
            initializeSubscribeBanner(banner);
        });
    };

    Site.behaviors.subscribeBanner = {
        attach(context) {
            initializeSubscribeBanners(context);
        }
    };

}(Site));
