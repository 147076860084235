import { onDomReady } from '@oecd/util-site/on-dom-ready';

(function() {

    'use strict';

    const attachSecondaryNavigationDimensionsListener = (secondaryNavigation) => {
        let rafSecondaryNavigationResizeListener;

        const secondaryNavigationDimensionsListener = () => {
            const secondaryNavigationHeight = secondaryNavigation.offsetHeight;
            document.documentElement.style
                .setProperty('--secondary-navigation-height', `${secondaryNavigationHeight}px`);
        };

        window.addEventListener('resize', () => {
            cancelAnimationFrame(rafSecondaryNavigationResizeListener);
            rafSecondaryNavigationResizeListener = requestAnimationFrame(secondaryNavigationDimensionsListener);
        });

        secondaryNavigationDimensionsListener();
    };

    const isHorizontallyVisible = (element, parent) => {
        const elementRect = element.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        // Check if the element is horizontally within the parent's visible area
        return elementRect.left >= parentRect.left && elementRect.right <= parentRect.right;
    };

    const scrollToActiveElement = (secondaryNavigation) => {
        const activeItem = secondaryNavigation.querySelector('[aria-current="page"]');
        const scrollArea = secondaryNavigation.querySelector('ul');

        if (activeItem && scrollArea) {
            // check if current element is visible inside scrollable area
            // else scroll it into view
            if (!isHorizontallyVisible(activeItem, scrollArea)) {
                scrollArea.scrollTo(activeItem.offsetLeft, 0);
            }
        }
    };

    const setJsIsSticky = (secondaryNavigation) => {
        if ('IntersectionObserver' in window) {
            const secondaryNavigationParent = secondaryNavigation.parentNode;
            const secondaryNavigationObserverOptions = {
                threshold: [1]
            };

            const secondaryNavigationObserverCallback = (entries) => {
                if (entries[0].intersectionRatio === 1) {
                    entries[0].target.classList.remove('js-is-stuck');
                } else {
                    entries[0].target.classList.add('js-is-stuck');
                }
            };

            const secondaryNavigationObserver =
                new IntersectionObserver(secondaryNavigationObserverCallback, secondaryNavigationObserverOptions);
            secondaryNavigationObserver.observe(secondaryNavigationParent);
        }
    };

    const initializeSecondaryNavigation = (secondaryNavigation) => {
        // Update custom property so that other components can take it into account
        attachSecondaryNavigationDimensionsListener(secondaryNavigation);

        // Attach class to HTML so that other components can take it into account
        document.documentElement.classList.add('js-secondary-navigation-enabled');
        document.documentElement.classList.add('js-sticky-scroll-interaction');

        // Scroll Active element in to view
        scrollToActiveElement(secondaryNavigation);

        // Set js-is-stuck class on Content language picker when it is sticky
        setJsIsSticky(secondaryNavigation);
    };

    const initializeSecondaryNavigations = (context) => {
        const secondaryNavigations = context.querySelectorAll('.js-secondary-navigation');
        [...secondaryNavigations].forEach(secondaryNavigation => {
            initializeSecondaryNavigation(secondaryNavigation);
        });
    };

    onDomReady(() => {
        initializeSecondaryNavigations(document);
    });

}());
