import { ajax } from '@oecd/util-site/ajax';

(function(Site) {

    'use strict';

    const openDialog = (dialog) => {
        document.documentElement.style.overflowY = 'hidden';
        document.documentElement.style.paddingInlineEnd = 'var(--scrollbar-width)';
        dialog.showModal();
    };

    // eslint-disable-next-line max-lines-per-function
    const initializeOverlayButton = async (context, path, overlayButton) => {
        // Check if modal was already created to prevent a new request and DOM interactions.
        if (!overlayButton.hasAttribute('data-modal-initialized')) {
            try {
                // Check if there are other modal triggers pointing to the same dialog.
                const modalTriggers = context.querySelectorAll(`.js-button-overlay[data-overlay-path="${path}"`);

                // Disable button.
                // In case the request takes long it cannot trigger other requests.
                modalTriggers.forEach(modalTrigger => {
                    modalTrigger.setAttribute('disabled', 'true');
                });

                const fragment = await ajax(path);

                // Get dialog and close btn node.
                const fragmentModalEl = fragment.querySelector('.js-overlay');
                const overLayCloseBtn = fragment.querySelector('.js-modal-close-button');

                // Add it to document.
                document.body.appendChild(fragment);

                // Remove disabled state from triggers.
                modalTriggers.forEach(modalTrigger => {
                    modalTrigger.removeAttribute('disabled');
                    modalTrigger.setAttribute('data-modal-initialized', 'true');
                    modalTrigger.addEventListener('click', () => {
                        openDialog(fragmentModalEl);
                    }, false);
                });

                // Focus trigger so that focus can be restored to trigger when modal is closed.
                overlayButton.focus();

                // Open dialog element.
                openDialog(fragmentModalEl);

                // Click outside.
                fragmentModalEl.addEventListener('click', (event) => {
                    if (event.target === fragmentModalEl) {
                        fragmentModalEl.close();
                    }
                });

                fragmentModalEl.addEventListener('close', () => {
                    document.documentElement.style.overflowY = '';
                    document.documentElement.style.paddingInlineEnd = '';
                });

                overLayCloseBtn.addEventListener('click', () => {
                    fragmentModalEl.close();
                }, false);
            } catch (error) {
                // Disable button in order to not trigger to many requests.
                overlayButton.setAttribute('disabled', 'true');
                console.warn('Overlay could not be triggered', error); // eslint-disable-line no-console
            }
        }
    };

    const initializeOverlayButtons = (context) => {
        const overlayButtons = context.querySelectorAll('.js-button-overlay[data-overlay-path]');

        [...overlayButtons].forEach(overlayButton => {
            const overlayPath = overlayButton.getAttribute('data-overlay-path');

            if (overlayPath) {
                overlayButton.addEventListener('click', () => {
                    initializeOverlayButton(context, overlayPath, overlayButton);
                }, false);
            }
        });
    };

    Site.behaviors.overlay = {
        attach(context) {
            initializeOverlayButtons(context);
        }
    };

}(Site));
